<template>
  <div class="relative w-full">
    <div v-if="loading">
      <Loading />
    </div>
    <div
      v-else
      class="py-2 md:grid md:grid-cols-3 pb-4 md:gap-8 max-w-screen-lg mx-auto px-4 sm:px-10 md:px-16 lg:px-24"
    >
      <!-- User Information -->
      <div class="col-span-3">
        <div class="text-white flex w-full pt-2">
          <h4 class="md:block z-1 hidden">ACCOUNT DETAILS</h4>
          <div class="hidden md:block ml-auto text-sm font-semibold z-1 py-1">
            <span v-if="commercial">Pro Account </span><span v-else>Warehouse Member</span>
            <span v-if="commercial">#{{ user.accountNumber }}</span>
          </div>
        </div>
        <div
          class="relative text-gray-800 rounded-md my-2 pt-2 pb-3 px-4 md:p-6 bg-white shadow md:min-h-32"
        >
          <div class="flex items-center">
            <img
              v-if="user.avatar"
              :src="user.avatar"
              :alt="`${user.name}'s profile picture`"
              class="rounded-full mr-2 h-20 w-20"
            />
            <div v-else class="rounded-full mr-2 h-20 w-20 text-white bg-gray-100 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex-shrink-0 h-20 w-20 rounded-full"
                viewBox="0 0 20 20"
                fill="gray"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="items-baseline">
              <router-link
                to="/user/settings"
                tag="button"
                class="absolute right-0 top-0 p-1 mx-1 mt-px text-xl text-primary"
              >
                <font-awesome-icon icon="cog" />
              </router-link>
              <div v-if="user.accountNumber" class="text-gray-500 text-sm md:hidden">
                Pro Account #{{ user.accountNumber }}
              </div>
              <div class="flex">
                <div v-if="user.jobTitle" class="text-sm">
                  <p class="font-medium inline-block">{{ user.jobTitle }}</p>
                  <p v-if="user.accountRole" class="inline-block whitespace-pre">
                    - {{ user.accountRole }} Account
                  </p>
                </div>
              </div>
              <h2 class="font-thin hidden md:block">{{ user.name }}</h2>
              <h3 class="md:hidden">{{ user.name }}</h3>
              <div class="md:absolute md:right-1 md:bottom-4 md:mb-3 md:text-sm font-medium">
                <p class="font-thin md:inline-block whitespace-pre">Personal email:</p>
                {{ user.email }}
              </div>
            </div>
          </div>
          <!-- Business information Desktop -->
          <div class="hidden md:block text-sm mt-4" v-if="commercial && company">
            <h4>{{ company.name }}</h4>
            <div class="absolute right-1 bottom-1 font-medium">
              <p class="font-thin inline-block whitespace-pre">Business address:</p>
              {{ company.address }}, {{ company.city }}, {{ company.state }}
              {{ company.zip }}
            </div>
            <div class="absolute right-1 bottom-3 mb-2 font-medium">
              <p class="font-thin md:inline-block whitespace-pre">Business email:</p>
              {{ company.email }}
            </div>
            <div class="absolute right-1 bottom-2 mb-1 font-medium">
              <p class="font-thin md:inline-block whitespace-pre">Business phone:</p>
              {{ company.phone }}
            </div>
            <div v-if="company.taxExempt" class="text-green-500">Tax Exempt</div>
          </div>
        </div>
        <!-- End Business information Desktop -->
      </div>
      <!-- End of user information -->

      <!-- Recent Orders -->
      <ListofUserOrders
        class="z-1"
        :class="commercial || authorized ? 'col-span-2' : 'col-span-3'"
        :page="false"
        :commercial="commercial"
        :user="user"
      />
      <!-- End of Recent Orders -->

      <!-- Start Notifications -->
      <NotificationList v-if="authorized" />
      <!-- End Notifications -->

      <!-- Specially priced Items -->
      <div v-if="commercial" class="z-1">
        <!-- <div
          v-if="user.accountRole === 'Primary' && company.creditLimit > 0"
          class="mb-4 hidden md:block"
        >
          <h4>BALANCE</h4>
          <div class="bg-white rounded p-2 text-right">$ {{ company.runningTotal.toFixed(2) }}</div>
        </div> -->

        <div v-if="showSpecialPricing" class="mb-4">
          <h4 class="hidden md:block">SPECIAL PRICING</h4>
          <Special :page="false" />
        </div>
        <!-- Start Team -->
        <div class="hidden md:block">
          <Team :page="false" />
        </div>
        <!-- End Team -->
      </div>
      <!-- End or specially priced items -->

      <!-- Start Mobile Team -->
      <div v-if="commercial" class="my-2 md:hidden z-1">
        <Team :page="false" />
      </div>
      <!-- End Team -->

      <!-- Start Business Mobile -->
      <div
        v-if="commercial && company"
        class="relative rounded-md shadow my-4 pt-2 pb-3 px-4 md:p-6 bg-white md:hidden font-medium z-1"
      >
        <router-link
          to="/user/settings"
          tag="button"
          class="absolute right-0 top-0 p-1 mx-1 mt-px text-xl text-primary"
        >
          <font-awesome-icon icon="cog" />
        </router-link>
        <h4 class="my-1">{{ company.name }}</h4>
        <div class="mt-4 mb-1">
          <p class="font-thin whitespace-pre">Business Address:</p>
          {{ company.address }}, {{ company.city }}, {{ company.state }}
          {{ company.zip }}
        </div>
        <div class="my-1">
          <p class="font-thin whitespace-pre">Business email:</p>
          {{ company.email }}
        </div>
        <div class="my-1">
          <p class="font-thin whitespace-pre">Business phone:</p>
          {{ company.phone }}
        </div>
        <div v-if="company.taxExempt" class="text-green-500">Tax Exempt</div>
      </div>
      <!-- End Business Mobile -->
    </div>
    <!-- Favorites -->
    <div class="z-1 w-full lg:w-3/4 relative mx-auto" v-if="favorites && favorites.length > 0">
      <div class="flex items-center justify-between md:justify-start mx-6">
        <h4 class="md:mr-12">Favorites</h4>
        <router-link to="/user/favorites"> View all </router-link>
      </div>
      <ProductCarousel :products="favorites" :white="false" :loading="false" :sortByCost="false" />
    </div>
  </div>
</template>

<script>
import Team from './Team';
import Special from './SpecialPricing';
import ListofUserOrders from './ListofUserOrders';
import ProductCarousel from '@/components/items/ProductCarousel';
import Loading from '@/components/shared/Loading';
import NotificationList from '@/components/user/notifications/NotificationList';
export default {
  data: function () {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
    showSpecialPricing() {
      const products = this.$store.getters['company/getSpecialPricedItems'];
      if (!products) return false;
      if (products.length === 0) return false;
      return true;
    },
    commercial() {
      return this.$store.getters.getCommercial;
    },
    favorites() {
      return this.$store.getters['products/getFavorites']();
    },
    company() {
      if (this.user.company) {
        return this.$store.getters['company/getCompany'];
      }
      return {};
    },
    loading() {
      const companyLoading = this.$store.getters['company/getLoading'];
      const userLoading = this.$store.getters['user/getLoading'];
      if (companyLoading || userLoading) {
        return true;
      }
      return false;
    },
    authorized() {
      return this.$store.getters.getAuthorized;
    },
  },
  watch: {},
  mounted() {
    if (this.user && this.user.company) {
      this.$store.dispatch('company/getCompany', this.user.company._id);
    }
  },
  methods: {},
  components: {
    Team,
    Special,
    ListofUserOrders,
    ProductCarousel,
    Loading,
    NotificationList,
  },
};
</script>
<style scoped></style>
