<template>
  <div class="py-6 md:pt-0 md:-mt-2 h-full">
    <div
      v-if="loading"
      class="sm:bg-gray-800 animate-pulse h-1/2-screen sm:mx-auto sm:max-w-xl sm:px-8 sm:py-4 sm:shadow sm:rounded relative"
    ></div>
    <div v-else-if="order === null">We're sorry but the order couldn't be found</div>
    <div v-else>
      <div class="sm:bg-white sm:mx-auto sm:max-w-xl sm:px-8 sm:py-4 sm:shadow sm:rounded relative">
        <div class="w-full flex justify-between">
          <div class="font-medium">
            <h3 :class="getPaymentClass(order)">
              {{ order.paid ? 'Paid' : order.overdue ? 'Overdue' : 'Unpaid' }}
            </h3>
          </div>
          <div class="text-right z-10">
            <h3 class="font-medium">
              <span v-if="order.orderName">{{ order.orderName }}</span>
              <span v-else> Order #{{ order.orderNumber }} </span>
            </h3>
            <h4 :class="getColor(order)" class="font-semibold">
              {{ order.status }}
            </h4>
          </div>
        </div>

        <div class="border-b border-primary pb-4 w-full">
          <!-- User Information -->
          <table class="align-top text-left text-sm w-full sm:w-auto">
            <th>
              <h4>Details</h4>
            </th>
            <tr>
              <td>Order Date</td>
              <td class="text-primary font-medium">{{ order.date }}</td>
            </tr>
            <tr v-if="order.deliveryDate">
              <td :class="order.status === 'Pending' ? 'py-1' : ''">Requested Delivery</td>
              <td class="text-primary font-medium">
                <span v-if="order.status !== 'Pending'">{{ order.deliveryDate }}</span>
                <span v-else>
                  <input
                    type="date"
                    class="styled"
                    v-model="order.deliveryDate"
                    @change="updateOrder"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td class="text-primary font-medium">{{ order.name }}</td>
            </tr>
            <tr>
              <td>Account #</td>
              <td class="text-primary font-medium">{{ order.associatedAccountNumber }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td class="text-primary font-medium">{{ order.email }}</td>
            </tr>
            <tr v-if="order.orderName">
              <td>Order Name</td>
              <td class="text-primary font-medium">{{ order.orderName }}</td>
            </tr>
            <tr v-if="order.purchaseOrder">
              <td>Purchase Order</td>
              <td class="text-primary font-medium">{{ order.purchaseOrder }}</td>
            </tr>
            <!-- End User Information -->

            <!-- Start of billing -->
            <th>
              <h4 class="mt-3">Payment</h4>
            </th>
            <tr>
              <td class="pr-4">Billing Address</td>
              <td v-if="order.billingAddress" class="text-primary font-medium">
                {{ order.billingAddress }}
                <br />
                {{ order.billingCity }}, {{ order.billingState }} {{ order.billingZip }}<br />
              </td>
            </tr>
            <!-- End of Billing -->

            <!-- Start of Shipping Address -->
            <th>
              <h4 class="mt-3">Shipping</h4>
            </th>
            <tr>
              <td class="pr-4">Shipping Address</td>
              <td class="text-primary font-medium">
                {{ order.shippingAddress }}
                <br />
                {{ order.shippingCity }}, {{ order.shippingState }} {{ order.shippingZip }}<br />
              </td>
            </tr>
            <!-- End Shipping address -->
          </table>
        </div>

        <!-- List of Cart -->
        <div
          v-for="cart in order.carts"
          :key="cart._id"
          class="my-4 border-b border-primary pb-2 text-sm relative"
        >
          <h4 class="text-left -mb-2 relative mt-4" v-if="order.carts.length > 0">
            <div>
              <span> #{{ cart.name ? cart.name : order.orderNumber }} </span>
              <span v-if="order.overdue && !cart.paid" class="text-red text-xs">overdue</span>
              <span v-else-if="!cart.paid && !order.paid" class="text-gray-600 text-xs"
                >unpaid</span
              >
            </div>
            <div class="absolute right-0 top-0 text-xs my-1">
              {{ cart.status }}
            </div>
          </h4>

          <div v-for="item in cart.cartItems" :key="item._id" class="grid grid-cols-6 my-6">
            <div class="w-12 h-12 p-2 rounded-lg shadow overflow-hidden row-span-2 my-2">
              <router-link :to="`/product/${item.productId}`">
                <img v-lazy="item.image" :alt="`Image of ${item.name}`" style="" class="" />
              </router-link>
            </div>
            <div class="col-span-5 font-semibold break-words">{{ item.name }}</div>
            <div class="col-span-2 break-words">{{ item.productNum }}</div>
            <div v-if="!secondary" class="break-words">${{ item.price.toFixed(2) }}</div>
            <div class="break-words">{{ item.fulfilled }} / {{ item.quantity }}</div>
            <div class="font-semibold break-words">
              ${{ (item.price.toFixed(2) * item.quantity).toFixed(2) }}
            </div>
          </div>
          <div v-if="cart.trackingNumber" class="text-xs">Tracking #{{ cart.trackingNumber }}</div>
          <div
            class="flex justify-end"
            v-if="
              order.carts.length > 1 && !cart.paid && user.accountRole === 'Primary' && !order.paid
            "
          >
            <button class="text-right text-action" @click="setPartialPayment(cart)">Pay</button>
          </div>
        </div>
        <div class="my-4 border-b border-primary pb-2 text-sm" v-if="order.customerNote">
          <h4>Customer Note:</h4>
          {{ order.customerNote }}
        </div>
        <!-- End list of Cart -->
        <div>
          <!-- Order totals -->
          <Totals
            :products="setTotalProducts()"
            :shipping="order.shipping"
            :taxRate="order.taxRate"
            :discount="order.discount"
          />
        </div>
        <div class="flex justify-end mt-8">
          <DualButton
            v-if="commercial"
            :options="dualButtonOptions"
            verticalPosition="top"
            @execute="handleDualButton($event)"
            position="right"
          />
          <!-- <button v-else class="btn-action rounded" @click="startReturn()">Start return</button> -->
        </div>
      </div>
      <!-- Start of return -->
      <div
        v-if="order_return"
        class="sm:bg-white sm:mx-auto sm:max-w-xl sm:px-8 sm:py-4 sm:shadow sm:rounded relative mt-8"
      >
        <div class="w-full flex justify-between border-b pb-4">
          <div class="font-medium text-gray-500">
            <OrderReturnStatus :status="order_return.status" />
          </div>
          <div class="text-right z-10">
            <h4 class="font-medium">
              <span> Order Return #{{ order_return.order_number }} </span>
            </h4>
          </div>
        </div>

        <div
          v-for="item in order_return.items"
          :key="`return-${item.productNum}`"
          class="grid grid-cols-6 my-6"
        >
          <div class="w-12 h-12 p-2 rounded-lg shadow overflow-hidden row-span-2 my-2">
            <router-link :to="`/product/${item.productId}`">
              <img v-lazy="item.image" :alt="`Image of ${item.name}`" style="" class="" />
            </router-link>
          </div>
          <div class="col-span-5 font-semibold break-words">{{ item.name }}</div>
          <div class="col-span-2 break-words">{{ item.productNum }}</div>
          <div class="break-words">${{ item.price.toFixed(2) }}</div>
          <div class="break-words flex justify-center">
            <input
              type="number"
              v-if="order_return.status === 0"
              v-model="item.quantity"
              @change="changeToMax(item)"
              class="w-10 styled no-numbers border"
            />
            <span v-else>{{ item.quantity }}</span>
          </div>
          <div class="font-semibold break-words">
            ${{ (item.price.toFixed(2) * item.quantity).toFixed(2) }}
          </div>
        </div>

        <Totals
          class="border-t pt-4"
          :products="order_return.items"
          :shipping="0"
          :taxRate="order.taxRate"
          :discount="order.discount"
          :showShipping="false"
        />
        <div class="mt-10" v-if="order_return.customer_note">
          <div>Note:</div>
          <textarea
            v-if="order_return.status === 0"
            v-model="order_return.customer_note"
            class="styled w-full shadow"
          />
          <span v-else>
            {{ order_return.customer_note }}
          </span>
        </div>
        <div
          v-if="order.paymentType === 'Credit Card' && order_return.status === 0"
          class="my-4 text-center"
        >
          <label class="text-base font-medium text-gray-900">Refund method</label>
          <p class="text-sm leading-5 text-gray-500">How do you prefer to receive a refund?</p>
          <fieldset class="mt-4">
            <legend class="sr-only">Repayment method</legend>
            <div
              class="space-y-4 sm:flex sm:items-center justify-center sm:space-y-0 sm:space-x-10"
            >
              <div class="flex items-center">
                <input
                  id="credit-card"
                  name="credit-method"
                  value="Credit Card"
                  type="radio"
                  v-model="order_return.return_method"
                  class="h-4 w-4 border text-blue-600 border-gray-300"
                />
                <label for="credit-card" class="ml-3 block text-sm font-medium text-gray-700">
                  credit card
                </label>
              </div>

              <div class="flex items-center">
                <input
                  id="company-credit"
                  name="credit-method"
                  value="In-store Credit"
                  v-model="order_return.return_method"
                  type="radio"
                  class="h-4 w-4 border text-blue-600 border-gray-300"
                />
                <label for="company-credit" class="ml-3 block text-sm font-medium text-gray-700">
                  in-store credit
                </label>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="flex justify-center" v-if="order_return.status === 0">
          <button @click="submitReturn()" class="border rounded p-2 hover:bg-gray-200">
            Submit return
          </button>
        </div>
      </div>
      <!-- End or return -->
    </div>
  </div>
</template>

<script>
import Totals from '@/components/shared/NewOrderTotals.vue';
import { status as getColor } from '@/assets/functions/getColor.js';
import DualButton from '@/components/shared/DualButton.vue';
import OrderReturnStatus from '@/components/shared/OrderReturnStatus.vue';

export default {
  data: function () {
    return {};
  },
  computed: {
    secondary() {
      if (this.$store.state.user.user.accountRole === 'Secondary') {
        return true;
      }
      return false;
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    commercial() {
      return this.$store.getters.getCommercial;
    },
    order: {
      get() {
        return this.$store.getters['user/getOrder'];
      },
    },
    order_return: {
      get() {
        return this.$store.getters['user/getReturnObject'];
      },
    },
    dualButtonOptions() {
      const options = [];
      // if (this.order.paid || this.order.status.includes('Fulfilled')) {
      //   options.push({
      //     name: 'Start return',
      //     buttonMethod: 'start_return',
      //   });
      // }

      if (this.commercial) {
        options.push({
          name: 'Duplicate',
          buttonMethod: 'duplicate',
        });
      }
      if (!this.order.paid) {
        options.push({
          name: 'Pay order',
          buttonMethod: 'pay_order',
        });
        options.push({
          name: 'Cancel order',
          buttonMethod: 'cancel',
        });
      }
      return options;
    },
    loading() {
      return this.$store.getters['user/getOrderLoading'];
    },
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  methods: {
    handleDualButton(option) {
      switch (option.buttonMethod) {
        case 'start_return':
          this.startReturn();
          return;
        case 'duplicate':
          this.duplicateOrder();
          return;
        default:
          this.setFullPayment();
      }
    },
    setTotalProducts() {
      let products = [];
      for (const cart of this.order.carts) {
        products = products.concat(cart.cartItems);
      }
      return products;
    },
    fetchData() {
      this.$store.dispatch('user/getOrder', this.$route.params.id);
    },
    getColor: getColor,
    duplicateOrder() {
      this.$store.dispatch('order/duplicateOrder', this.order);
    },

    updateOrder() {
      this.$store.dispatch('order/updateOrder', this.order);
    },
    getPaymentClass(order) {
      switch (order.paid) {
        case true:
          return 'text-green-200';
        case false:
          return 'text-red';
      }
    },
    setPartialPayment(cart) {
      this.$store.commit('delayedPayments/setPartialPaymentOrder', {
        cart,
        order: {
          order_id: this.order._id,
          cart_id: cart._id,
          name: this.order.name,
          email: this.order.email,
          orderName: cart.name ? cart.name : this.order.orderNumber,
          purchaseOrder: this.order.purchaseOrder,
          billingAddress: this.order.billingAddress,
          billingCity: this.order.billingCity,
          billingState: this.order.billingState,
          billingZip: this.order.billingZip,
          taxRate: this.order.taxRate,
        },
      });

      this.$router.push({
        name: 'Delayed Payment',
        params: { order_id: this.order._id, cart_id: cart._id },
      });
    },
    startReturn() {
      const cartMap = new Map();
      this.order.carts.forEach((el) => {
        el.cartItems.forEach((kal) => {
          const val = { ...kal };
          if (cartMap.has(val.productNum)) {
            const nVal = cartMap.get(val.productNum);
            nVal.fulfilled += val.quantity;
            cartMap.set(val.productNum, nVal);
          } else {
            val.fulfilled = val.quantity;
            val.quantity = 0;
            val._id = undefined;
            cartMap.set(val.productNum, val);
          }
        });
      });
      const cart = [...cartMap.values()];

      this.$store.commit('user/setOrderReturn', {
        order_id: this.order._id,
        order_number: this.order.orderNumber,
        status: 0,
        total: 0,
        items: cart,
      });
    },
    setFullPayment() {
      this.$store.commit('delayedPayments/setFullPaymentOrder', {
        order: {
          order_id: this.order._id,
          name: this.order.name,
          email: this.order.email,
          orderName: this.order.orderNumber,
          purchaseOrder: this.order.purchaseOrder,
          billingAddress: this.order.billingAddress,
          billingCity: this.order.billingCity,
          billingState: this.order.billingState,
          billingZip: this.order.billingZip,
          shipping: this.order.shipping,
          discount: this.order.discount,
          taxRate: this.order.taxRate,
        },
        carts: this.order.carts,
      });
      this.$router.push({ name: 'Delayed Payment', params: { order_id: this.order._id } });
    },
    getReturnStatus(status) {
      switch (status) {
        case 0:
          return 'Not submitted';
        case 1:
          return 'Unprocessed';
        case 2:
          return 'Processed';
        case 3:
          return 'Refund granted';
      }
    },
    changeToMax(item) {
      if (item.quantity > item.fulfilled) {
        item.quantity = item.fulfilled;
      }
      if (item.quantity < 0) {
        item.quantity = 0;
      }
    },
    submitReturn() {
      let flag = false;
      this.order_return.items.some((el) => {
        if (el.quantity === 0) {
          flag = flag ? flag : false;
          return;
        } else {
          flag = true;
        }
      });
      if (!flag) {
        this.$store.commit('setError', {
          message: 'Return cannot be submitted without any products.',
          color: 'red',
        });
        return;
      }
      this.$store.dispatch('user/submitReturn', this.order_return);
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    Totals,
    DualButton,
    OrderReturnStatus,
  },
};
</script>
<style scoped>
td {
  vertical-align: top;
}

div.grid > div {
  margin-top: auto;
  margin-bottom: auto;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
