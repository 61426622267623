<template>
  <div>
    <div class="flex justify-between">
      <h4>
        Notifications
        <!-- <font-awesome-icon icon="sync-alt" /> -->
      </h4>
      <button @click="markAllAsRead()" class="text-xs">mark all as read</button>
    </div>
    <div
      class="flex flex-col relative my-4 md:my-2 bg-white shadow rounded overflow-hidden"
      style="max-height: 100vh"
    >
      <div v-if="notifications && notifications.length > 0" class="overflow-y-auto">
        <div
          v-for="(notification, notifIdx) in notifications"
          :key="notification._id"
          :class="notifIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
          class="flex justify-between"
        >
          <div class="px-2 md:p-3 whitespace-nowrap font-medium">
            <div class="text-gray-900">
              {{ notification.title }}
            </div>
            <div class="text-xs text-gray-900">
              {{ notification.details }}
            </div>
          </div>

          <div class="flex flex-col divide-y divide-gray-200">
            <div class="flex-1 flex">
              <button
                v-if="!notification.read"
                type="button"
                @click="markAsRead(notification)"
                class="w-full border border-transparent rounded-none rounded-tr-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-primary hover:text-action focus:outline-none focus:z-10"
              >
                Read
              </button>
              <button
                v-else
                type="button"
                @click="markAsRead(notification)"
                class="w-full border border-transparent rounded-none rounded-tr-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-primary hover:text-action focus:outline-none focus:z-10"
              >
                Unread
              </button>
            </div>
            <div class="flex-1 flex" v-if="notification.link">
              <button
                type="button"
                @click="link(notification)"
                class="w-full border border-transparent rounded-none rounded-br-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2"
              >
                Link
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center p-4">Well done You are all caught up!</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    notifications() {
      return this.$store.getters['notifications/getNotifications'];
    },
  },
  methods: {
    link(notification) {
      switch (notification.type) {
        case 3:
          this.$router.push('/admin/users/' + notification.link + '/company');
          break;
        case 0:
        case 1:
        case 2:
        case 4:
        case 6:
        case 7:
        case 8:
          this.$router.push('/admin/orders/' + notification.link);
          break;
        case 5:
          this.$router.push('/admin/products/' + notification.link);
          break;
        case 9:
        case 10:
          break;
        case 11:
          this.$router.push('/product/' + notification.link);
          break;
        default:
      }
    },
    markAsRead(notification) {
      notification.read = notification.read ? false : true;
      this.$store.dispatch('notifications/updateNotification', notification);
    },
    getNotificationIcon(type) {
      switch (type) {
        case 0:
          return;
        case 1:
          return ``;
      }
    },
    markAllAsRead() {
      this.$store.dispatch('notifications/markAllNotificationsAsRead');
    },
  },
};
</script>

<style></style>
