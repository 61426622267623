<template>
  <div class="min-h-screen sm:bg-gray-200 bg-gray-200">
    <div class="px-6 pb-2 sm:max-w-lg md:max-w-xl mx-auto">
      <h1 class="mb-2 text-center text-gray-900">Set Password</h1>
      <form @submit.prevent="setPassword">
        <fieldset>
          <input
            class="styled rounded w-full text-gray-800 my-1"
            v-model="password"
            type="password"
            placeholder="New Password"
            autocomplete="off"
            required
          />

          <input
            class="styled rounded w-full text-gray-800 my-1"
            v-model="cPassword"
            type="password"
            placeholder="Confirm Password"
            autocomplete="off"
            required
          />

          <div v-if="error" class="text-red mb-4">{{ error }}</div>

          <button type="submit" class="w-full btn-white rounded-full md:rounded-lg my-3 h-16">
            Submit
          </button>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode';

export default {
  data: () => ({
    password: '',
    cPassword: '',
    error: '',
  }),
  methods: {
    async setPassword() {
      if (this.password !== null) {
        if (this.password !== this.cPassword) {
          this.error = 'Passwords do not match';
          return;
        }
        const payload = {
          resetToken: this.$route.query.resetToken,
          password: this.password,
        };
        this.error = null;
        await this.$store.dispatch('user/setPassword', payload);
        this.$router.push('/user');
      }
    },
  },
  async mounted() {
    const decodedToken = VueJwtDecode.decode(this.$route.query.resetToken);
    const currentTime = new Date().getTime() / 1000;
    if (currentTime > decodedToken.exp) {
      this.$store.commit(
        'setError',
        {
          message:
            'Reset Password link has expired. Please request new reset password link to reset your password.',
          color: 'red',
        },
        {
          root: true,
        }
      );
      return this.$router.push('/user');
    }
    const tokenValid = await this.$store.dispatch('user/verifyResetToken', {
      resetToken: this.$route.query.resetToken,
    });
    if (!tokenValid) {
      return this.$router.push('/user');
    }
  },
};
</script>
<style scoped></style>
