<template>
  <div class="md:px-8">
    <div v-if="favorites.length > 0" class="mx-auto">
      <transition-group
        name="product"
        tag="div"
        class="flex-wrap flex justify-around md:justify-start"
      >
        <div v-for="(product, index) in favorites" :key="index">
          <ProductTile :productInfo="product" :length="favorites.length" :sortByCost="false" />
        </div>
      </transition-group>
    </div>
    <div v-else class="text-center">
      <h3 class="text-primary">You have not saved any products to your favorites yet!</h3>
    </div>
  </div>
</template>

<script>
import ProductTile from '@/components/items/ProductTile';

export default {
  data: function () {
    return {};
  },
  computed: {
    favorites() {
      return this.$store.getters['products/getFavorites']();
    },
  },
  watch: {},
  methods: {},
  components: {
    ProductTile,
  },
  mounted() {},
};
</script>
<style scoped></style>
