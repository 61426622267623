<template>
  <div class="min-h-screen sm:bg-gray-200" :class="getParentBackgroundColor">
    <div v-if="user" class="relative mx-auto">
      <div
        v-if="$route.name !== 'Profile'"
        class="w-full text-center pt-4 relative md:h-24 shadow-md sm:shadow-none sm:text-left sm:p-0 sm:px-12 lg:px-24 z-1 max-w-screen-lg mx-auto px-4"
        :class="$route.name === 'User Settings' ? 'text-gray-800' : 'text-white'"
      >
        <div
          @click="handleNavigation()"
          class="text-md font-medium absolute sm:relative left-1 sm:left-auto sm:mr-2 cursor-pointer top-0 mt-5 pt-px sm:inline-block"
          :class="$route.name === 'User Settings' ? 'text-gray-800' : 'text-white'"
        >
          <font-awesome-icon icon="chevron-left" class="mr-2" /> {{ destination }}
        </div>
        <h4 class="hidden sm:inline-block font-thin">|</h4>
        <h4 class="sm:inline-block text-xl mt-px sm:ml-2 sm:font-normal">
          {{ $route.name }}
        </h4>
      </div>
      <div
        class="absolute flex justify-center inset-x-0 left-1/2 transform top-0 md:top-05 -translate-x-1/2 w-full overflow-hidden z-0 md:rounded-3xl"
        aria-hidden="true"
        v-if="$route.name !== 'User Settings'"
        style="max-width: 1300px"
      >
        <svg
          class="flex-shrink-0 -mt-32"
          width="1750"
          height="275"
          viewBox="0 0 1750 200"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path opacity=".75" d="M1465.84 308L16.816 0H1750v308h-284.16z" fill="#00A0B1" />
          <path opacity=".75" d="M1733.19 0L284.161 308H0V0h1733.19z" fill="#005785" />
          <path opacity=".75" d="M1733.19 308L16.816 0H1750v308h-284.16z" fill="#00A0B1" />
          <path opacity=".75" d="M1465.84 0L284.161 308H0V0h1733.19z" fill="#005785" />
        </svg>
      </div>
      <div>
        <router-view :commercial="commercial" />
      </div>
    </div>
    <!-- Logged out screen -->
    <div v-else class="text-primary pt-8 text-center min-h-screen bg-gray-200" key="loggedOut">
      <!-- <font-awesome-icon icon="lock" size="10x" class="" /> -->
      <div v-if="!showRegister">
        <h1 class="my-2 text-gray-900">Sign In</h1>
        <Login />
        <div class="mt-8 mb-4 text-gray-800">Don't have an account?</div>
        <div
          class="flex sm:flex-row flex-col mx-auto sm:w-128 justify-center sm:justify-between px-8 sm:p-0"
        >
          <router-link
            tag="button"
            to="/commercial"
            replace
            class="btn-white sm:w-1/2 my-2 sm:mr-2 rounded"
          >
            Sign up as a Pro Member
          </router-link>
          <button
            @click="showRegister = !showRegister"
            class="btn-action sm:w-1/2 my-2 sm:ml-2 rounded"
          >
            Sign up as a Warehouse Member
          </button>
        </div>
      </div>
      <div v-else class="px-8">
        <h3 class="my-2 text-gray-900 mb-16">Warehouse Member Sign Up</h3>
        <Register @close="showRegister = false" />
        <div class="mb-4">
          <span class="mr-4">Already have an account?</span>
          <button @click="showRegister = false" class="font-medium sm:px-2 py-1">
            <p class="underline inline-block">Login</p>
            <span class="ml-2">></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from './../components/Login';
import Register from './../components/Register';

export default {
  data() {
    return {
      showRegister: this.initialShowRegister,
    };
  },
  props: {
    initialShowRegister: { type: Boolean, default: false },
  },
  components: {
    Register,
    Login,
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
    commercial() {
      return this.$store.getters['getCommercial'];
    },
    getParentBackgroundColor() {
      if (this.$route.name == 'Order') return 'bg-white';
      else return 'bg-gray-200';
    },
    getBackgroundColor() {
      if (this.$route.name == 'User Settings') return 'sm:bg-primary-alt';
      else return 'sm:bg-gray-200';
    },
    destination() {
      switch (this.$route.name) {
        case 'Delayed Payment':
          return 'Order';
        default:
          return 'Profile';
      }
    },
  },
  methods: {
    handleNavigation() {
      switch (this.$route.name) {
        case 'Delayed Payment':
          this.$router.push(`/user/orders/${this.$route.params.order_id}`);
          break;
        default:
          this.$router.push('/user');
      }
    },
  },
};
</script>

<style scoped>
.minHeight {
  min-height: 100vh;
}

img {
  min-width: 100%;
  object-fit: cover;
  height: inherit;
}

/* This is for iPad and smaller */
@media only screen and (max-width: 900px) {
  .curvedDiv {
    width: 100%;
    height: 12rem;
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
  }
}

.stickyDiv {
  position: sticky;
  top: 4rem;
  left: 0;
  right: 0;
}
</style>
