<template>
  <div class="px-6 pb-2 sm:max-w-lg md:max-w-xl mx-auto">
    <div class="flex flex-col md:flex-row mt-20"></div>

    <form @submit.prevent="login">
      <fieldset>
        <input
          class="styled rounded-t-lg w-full text-2xl text-gray-800"
          v-model="email"
          type="email"
          placeholder="Email"
          autocomplete="email"
          required
        />

        <input
          class="styled rounded-b-lg w-full text-2xl text-gray-800 my-1"
          v-model="password"
          type="password"
          placeholder="Password"
          autocomplete="current-password"
          required
        />
        <div class="w-full flex justify-center">
          <button
            type="submit"
            class="w-full btn-white text-lg rounded-full md:rounded-lg my-3 h-16"
          >
            Login
          </button>
          <a
            :href="getGoogleUrl()"
            class="shadow-lg w-full my-3 md:rounded-lg md:my-3 md:ml-1 text-lg rounded h-16 flex justify-between px-2 items-center bg-white hover:bg-gray-200"
          >
            <img src="/images/icons/google-icon.svg" class="w-12" alt="Google Icon" />
            <div>Sign in with Google</div>
          </a>
        </div>
      </fieldset>
    </form>

    <div class="w-full text-right text-primary mt-1">
      <button @click="showResetPassword = true">Forgot Password?</button>
    </div>
    <div>
      <Popup
        v-if="showResetPassword"
        :title="'Reset password'"
        class="text-left"
        @close="showResetPassword = false"
      >
        <div class="p-3 md:hidden">
          <h3 class="text-primary">Reset password</h3>
        </div>
        <div class="text-gray-800 md:font-normal md:h-auto md:mb-4 p-4 text-left">
          To reset your password, enter your email below and submit. An email will be sent to you
          with instructions about how to complete the process.
        </div>
        <form class="md:my-2 m-4" @submit.prevent="resetPassword">
          <div class="flex flex-col">
            <label for="reset-email" class="mb:mt-0 md:text-sm mt-2 mb-1">Email Address:</label>
            <input
              class="styled border shadow"
              type="email"
              id="reset-email"
              required
              v-model="resetEmail"
              placeholder="JaneDoe@example.com"
            />
          </div>
          <div class="text-right">
            <button class="btn-action md:my-4 md:ml-auto md:rounded rounded-full w-full mt-6">
              Reset Password
            </button>
          </div>
        </form>
      </Popup>
    </div>
  </div>
</template>

<script>
import Popup from '@/components/shared/PopUp';
import { getGoogleUrl } from '@/assets/utils/getGoogleUrl.js';

export default {
  data: () => ({
    email: '',
    password: '',
    attempts: 0,

    showResetPassword: false,
    resetEmail: null,
  }),
  methods: {
    getGoogleUrl,
    login() {
      this.attempts += 1;
      if (this.attempts < 5) {
        this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
        });
      }
    },
    resetPassword() {
      this.$store.dispatch('user/resetPassword', this.resetEmail);
    },
  },
  created() {
    if (this.$route.query && this.$route.query.code) {
      this.$store.dispatch('google', this.$route.query);
    }
  },

  components: {
    Popup,
  },
};
</script>
