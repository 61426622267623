<template>
  <div>
    <div class="hidden md:block">
      <h4>TEAM ACCOUNTS</h4>
    </div>
    <div :class="getWrapper()">
      <h4 class="md:hidden my-1">Team</h4>
      <div
        v-for="person in accounts"
        :key="person._id"
        class="select-none relative py-2 pl-3 pr-9 flex items-center"
      >
        <img
          v-if="person.avatar"
          :src="person.avatar"
          alt=""
          class="flex-shrink-0 h-6 w-6 rounded-full"
        />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          class="flex-shrink-0 h-6 w-6 rounded-full"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
            clip-rule="evenodd"
          />
        </svg>
        <div class="ml-3 block truncate text-sm">
          <div>
            {{ person.name }}
          </div>
          <div class="text-gray-500">
            <span v-if="person.company">{{ person.company.name }}</span>
            {{ person.jobTitle ? `(${person.jobTitle})` : null }}
          </div>
        </div>
        <button
          class="absolute right-0 md:-right-1 lg:right-0 top-0 hover:bg-red-300 px-2 rounded"
          :class="ownAccount(person._id)"
          @click="removeAssociated(person)"
        >
          <font-awesome-icon class="-mb-px" icon="times" />
        </button>
      </div>
      <div class="text-right">
        <button
          class="w-full font-medium rounded-full sm:w-1/3 md:w-full btn-action mt-8"
          @click="displayInvite = true"
        >
          Invite to Team
        </button>
      </div>
      <!-- Popup for the invitation -->
      <transition name="modal">
        <PopUp
          v-if="displayInvite"
          :title="'Invite to Team'"
          @close="(displayInvite = false), (newAssociatedEmail = null)"
          class="text-center"
        >
          <div class="md:hidden h-16 w-full py-3">
            <h3 class="text-primary">Invite to Team</h3>
          </div>
          <div class="px-8 pt-0">
            <div class="md:mt-4 mt-6 mb-4 text-sm md:text-base">
              This will create an account for the specified email. That account will be a part of
              your company.
            </div>
            <form @submit.prevent="addAssociatedAccount" class="mx-auto">
              <input
                autocomplete="name"
                v-model="name"
                type="text"
                placeholder="Name"
                class="m-2"
                required
              />
              <input
                autocomplete="phone"
                v-model="phone"
                type="tel"
                placeholder="Phone"
                class="m-2"
                required
              />
              <div class="relative w-64 mx-auto">
                <button
                  type="button"
                  @click="newAssociatedEmail = null"
                  class="absolute right-0 py-2 px-3 cursor-pointer rounded-r-sm hover:bg-red-300"
                >
                  <font-awesome-icon icon="times" />
                </button>
                <input
                  type="email"
                  placeholder="John@example.com"
                  required
                  style="padding: 0.5em"
                  class="styled shadow w-full"
                  value="Add an email address"
                  v-model="newAssociatedEmail"
                />
              </div>
              <div class="text-sm my-2 hidden md:block text-left">
                If the account is set to primary, it can place orders, pay for orders, and see your
                special pricing. If the account is a secondary account, it can add items to your
                carts but cannot place orders or pay for orders. Secondary accounts are designed for
                salesman who are planning a job while with the customer and wish to price out how
                much to charge for supplies or other similar roles.
              </div>
              <div class="flex justify-between md:w-1/2 mx-auto m-4">
                <label for="Primary">
                  <input id="Primary" value="Primary" type="radio" v-model="accountRole" /> Primary
                </label>
                <label for="Secondary">
                  <input id="Secondary" value="Secondary" type="radio" v-model="accountRole" />
                  Secondary
                </label>
              </div>
              <div class="w-full md:text-right md:mt-12">
                <button class="btn-action md:rounded w-full md:w-auto rounded-full">
                  Send Invitation
                </button>
              </div>
            </form>
          </div>
        </PopUp>
      </transition>
    </div>
  </div>
</template>

<script>
import PopUp from '@/components/shared/PopUp';

export default {
  data: function () {
    return {
      displayInvite: false,
      newAssociatedEmail: null,
      accountRole: 'Secondary',
      name: null,
      phone: null,
    };
  },
  props: {
    page: { type: Boolean, default: true },
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
    company() {
      return this.$store.getters['company/getCompany'];
    },
    accounts() {
      return this.company ? this.company.users : [];
    },
  },
  watch: {},
  methods: {
    ownAccount(id) {
      if (id === this.user._id) {
        return 'hidden';
      }
    },
    getWrapper() {
      if (this.page) {
        return 'p-4';
      }
      return 'rounded-md shadow my-4 md:my-2 pt-2 pb-3 px-4 md:p-6 bg-white';
    },
    addAssociatedAccount() {
      this.$store.dispatch('company/addAssociatedAccount', {
        name: this.name,
        phone: this.phone,
        email: this.newAssociatedEmail,
        accountRole: this.accountRole,
      });
      this.newAssociatedEmail = null;
      this.displayInvite = false;
    },
    removeAssociated(member) {
      if (
        !confirm('Are you sure you would like to remove this account as an associated account?')
      ) {
        return;
      }
      this.$store.dispatch('company/removeUser', {
        company_id: this.user.company._id,
        user_id: member._id,
      });
    },
  },
  components: {
    PopUp,
  },
};
</script>
<style scoped></style>
