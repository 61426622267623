<template>
  <div :class="page ? 'py-4' : 'rounded-md my-4 md:my-2 p-4 bg-white shadow'">
    <h4 v-if="!page" class="md:hidden my-1">Special Pricing</h4>
    <div>
      <ul
        class="relative z-0 divide-y divide-gray-200 overflow-y-auto overflow-x-hidden scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray"
        style="max-height: 90vh"
      >
        <li
          v-for="variant in variants"
          :key="variant.id"
          class="bg-white py-2 px-3 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
        >
          <div class="flex-shrink-0" v-if="variant.image">
            <img class="h-10 w-10 rounded shadow p-1" :src="variant.image" alt="" />
          </div>
          <div v-else class="flex-shrink-0">
            <div class="h-10 w-10 p-1"></div>
          </div>
          <router-link
            :to="`product/${variant.product_id}?variants=${variant._id}&productNum=${variant.productNum}`"
            class="grid grid-cols-4 items-center justify-between w-full"
          >
            <div class="focus:outline-none cursor-pointer max-w-xs col-span-3">
              <!-- Extend touch target to entire panel -->
              <p class="text-sm font-medium text-gray-900 overflow-hidden whitespace-no-wrap">
                {{ variant.product_name }} ({{ variant.productNum }})
              </p>
              <p class="text-sm text-gray-500 truncate">
                {{ variant.option }}
              </p>
            </div>
            <div class="ml-auto">${{ variant.price.toFixed(2) }}</div>
          </router-link>
        </li>
      </ul>
    </div>

    <div v-if="!page && displayMore" class="md:hidden text-center py-1 mt-3 col-span-2">
      <router-link
        to="/user/special-pricing"
        tag="button"
        class="btn-action w-full sm:w-1/3 rounded-full font-medium"
      >
        See More
        <font-awesome-icon class="cursor-pointer -mb-px ml-1" icon="chevron-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  props: {
    page: { type: Boolean, default: true },
  },
  computed: {
    variants() {
      const variants = this.$store.getters['company/getSpecialPricedItems'];
      if (variants === null) {
        return new Array();
      }

      if (window.innerWidth > 900 || this.page) {
        return variants;
      }

      return variants.slice(0, 5);
    },
    displayMore() {
      if (
        this.$store.getters['company/getSpecialPricedItems'] &&
        this.$store.getters['company/getSpecialPricedItems'].length > 5
      ) {
        return true;
      }
      return false;
    },
    getPadding() {
      if (this.$route.name === 'Profile') return ' px-3 md:px-0';
      else return ' px-8 md:px-0';
    },
  },
  watch: {},
  methods: {
    getBackgroundColor(index) {
      if (index % 2 !== 0) return 'bg-white';
      else return null;
    },
  },
  mounted() {},
};
</script>
<style scoped></style>
